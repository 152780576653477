import styled, { css } from 'styled-components';
import React from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { RmcCustomHook } from 'src/components/RmcComponent';
import { Button, IconDownload, IconEllipsis } from '@veneer/core';

export const ExportAllButton = (props: { disabled: boolean; setShowModal: (boolean) => void }) => {
  const { isPC, isTablet, isMobile } = RmcCustomHook.useResponsive();

  return (
    <>
      {(isPC || isTablet) && (
        <StyledButton
          data-testid="export-device-button"
          id="export-btn"
          onClick={() => props.setShowModal(true)}
          disabled={props.disabled}
          appearance="secondary"
          leadingIcon={<IconDownload />}
        >
          {t('table.export_all')}
        </StyledButton>
      )}

      {isMobile && (
        <StyledEllipsisButton
          data-testid="export-device-button"
          id="export-btn"
          onClick={() => props.setShowModal(true)}
          disabled={props.disabled}
          appearance="secondary"
          leadingIcon={<IconEllipsis />}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  min-width: 129px;
  min-height: 36px;
  ${(props) => {
    if (!props.disabled) {
      return css`
        margin-right: -20px !important;
      `;
    }
  }}
`;

const StyledEllipsisButton = styled(Button)`
  width: 40px;
  height: 40px;
`;

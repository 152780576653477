import { filterOptions } from 'src/components/RmcComponent/RmcUtils/constants';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';

export const useTableHandler = () => {
  const { rmcTableState, rmcTableDispatcher, tableConfig } = useTableStore();

  // change devices -> devicesInfo when global search is supported
  const handleSetTableData = (devices) => {
    if (devices.length > 0) {
      const newTableData = _getNewTableData(devices);
      rmcTableDispatcher.storeColumnContents(_getFriendlyTextContents(newTableData));
      rmcTableDispatcher.storeTableData(newTableData);
      if (rmcTableState.selectedTableData.length > 0) {
        rmcTableDispatcher.storeTableData(_getPreselectedItems(newTableData));
      }
    } else {
      rmcTableDispatcher.storeTableData([]);
    }
  };

  const getColumnContents = (devices) => {
    const newTableData = _getNewTableData(devices);
    return _getFriendlyTextContents(newTableData);
  };

  const _getNewTableData = (devices) => {
    return tableConfig.rendererConfig.cfgTableRenderers(devices)?.map((obj, index) => {
      // Add uid and rowConfig for checkbox function.
      return { ...obj, uid: index, rowConfig: { ...obj.rowConfig, selected: false } };
    });
  };

  const handleFooterCancel = () => {
    const updatedTableData = rmcTableState.tableData.map((data) => {
      data.rowConfig.selected = false;
      return data;
    });
    rmcTableDispatcher.storeTableData(updatedTableData);
    rmcTableDispatcher.storeSelectedTableData([]);
  };

  const handleSearch = (e = null, searchValue) => {
    rmcTableDispatcher.storeSearchItem(searchValue);
    rmcTableDispatcher.storeCurrentPage(1);

    // without search
    if (searchValue === '') {
      const allDeviceOptions = { ...rmcTableState.cfgApiRequestOptions, offset: 0 };
      delete allDeviceOptions.search;
      rmcTableDispatcher.storeCfgApiRequestOptions(allDeviceOptions);
      return;
    }

    // with search
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      offset: 0,
      search: searchValue,
    });
  };

  const handlePageChange = (page) => {
    rmcTableDispatcher.storeCurrentPage(page);
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      offset: page - 1,
    });
  };

  const handlePageSizeChange = (event, option) => {
    rmcTableDispatcher.storePageSize(option.value);
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      offset: 0,
      limit: option.value,
    });
  };

  const handleSort = (_, { id, type }) => {
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      sortBy: id,
      order: type,
    });
  };

  const handleSelect = (event, index) => {
    rmcTableDispatcher.storeTableData(_selectRow(event.target.checked, index));
  };

  const handleSelectAllPageItems = (event) => {
    rmcTableDispatcher.storeTableData(_selectAllPageItems(event.target.checked));

    const numberOfSelectedLength = rmcTableState.tableData.filter(
      (data) => data.rowConfig.selected === true,
    ).length;

    if (numberOfSelectedLength === 0) {
      _removeSelectedItems();
      return;
    }

    _setAllSelectedData();
  };

  const handleFilterOption = (e) => {
    if (e == null) return;
    const _filterList = [e.target.name];

    let filterOptions, filterValue;

    //Get previous filter
    if (rmcTableState.tableFilter == null) {
      filterOptions = new Map();
    } else {
      filterOptions = new Map(JSON.parse(rmcTableState.tableFilter));
    }

    //Get new filter category and filter value
    const tableFilterCategory = e.target.name.split(':')[0];
    const tableFilterValue = e.target.name.split(':')[1];

    //Get previous filter values
    filterValue = filterOptions.get(tableFilterCategory);
    if (filterValue == undefined) {
      //If undefined, there is no previous filter value. so create new value
      filterValue = [tableFilterValue];
    } else {
      //If there are previous filter values. then check that already there is new filter value
      if (filterValue.includes(tableFilterValue)) {
        //If it is, it means that action is "removing filter". So remove filter value from previous filter values
        filterValue.splice(filterValue.indexOf(tableFilterValue), 1);
      } else {
        filterValue.push(tableFilterValue);
      } //If it is not, add new filter value
    }

    //Final filter values has no value, then delete category from previous filter category
    if (filterValue.length == 0) {
      filterOptions.delete(tableFilterCategory);
    } else {
      filterOptions.set(tableFilterCategory, filterValue);
    }

    //If there is no category, then set null to tableFilter
    rmcTableDispatcher.storeTableFilter(
      filterOptions.size == 0 ? null : JSON.stringify(Array.from(filterOptions.entries())),
    );

    const _items = _filterList.join(',').toLowerCase();
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      connectionState: _items,
    });
    rmcTableDispatcher.storeCurrentPage(1);
  };

  const handleFilterClearAll = () => {
    rmcTableDispatcher.storeCfgApiRequestOptions({
      ...rmcTableState.cfgApiRequestOptions,
      connectionState: '',
    });
    rmcTableDispatcher.storeCurrentPage(1);
    rmcTableDispatcher.storeTableFilter(null);
  };

  const _getFriendlyTextContents = (tableData) => {
    const newTextContents = {};
    tableData?.forEach((obj) => {
      const currentTextContent = {};
      Object.entries(obj).forEach(([key, entry]) => {
        if (typeof entry !== 'object' || entry == null || entry['props'] === undefined) return;
        currentTextContent[`${key}`] = entry['props']?.value;
      });
      newTextContents[obj.objectUniqueKey] = currentTextContent;
    });
    return newTextContents;
  };

  const _getPreselectedItems = (getDeviceData) => {
    return getDeviceData.map((row) => {
      const newRow = row;
      const index = rmcTableState.selectedTableData.findIndex(
        (p) => p.deviceId === newRow.deviceId,
      );
      if (index > -1) newRow.rowConfig.selected = true;
      return newRow;
    });
  };

  const _removeSelectedItems = () => {
    rmcTableDispatcher.storeSelectedTableData([]);
    rmcTableDispatcher.storeSelectedTableData(_getSelectedDevicesFromOtherPages());
  };

  const _selectRow = (checked, id) => {
    const updatedDataArray = [...rmcTableState.tableData];
    const indexOfPagedData = updatedDataArray.findIndex((row) => row.uid === id);
    const indexOfSelectedData = rmcTableState.selectedTableData.findIndex(
      (row) => row.deviceId === updatedDataArray[indexOfPagedData].deviceId,
    );
    updatedDataArray[indexOfPagedData].rowConfig.selected = checked;

    if (_isAlreadySelected(indexOfSelectedData)) {
      rmcTableDispatcher.storeSelectedTableData((prev) =>
        prev.filter((data) => data.deviceId !== updatedDataArray[indexOfPagedData].deviceId),
      );
    } else {
      rmcTableDispatcher.storeSelectedTableData((prev) => [
        ...prev,
        updatedDataArray[indexOfPagedData],
      ]);
    }
    return updatedDataArray;
  };

  const _isAlreadySelected = (index) => index > -1;

  const _selectAllPageItems = (checked) => {
    return rmcTableState.tableData.map((row) => {
      const newRow = row;
      const index = rmcTableState.tableData.findIndex((p) => p.uid === newRow.uid);
      if (index > -1) newRow.rowConfig.selected = checked;
      return newRow;
    });
  };

  const _setAllSelectedData = () => {
    // find selected devices from other pages, cuz storedDevices only contain devices of current page (which is collection return)
    // insert tableStore from selectedData
    rmcTableDispatcher.storeSelectedTableData([
      ...rmcTableState.tableData,
      ..._getSelectedDevicesFromOtherPages(),
    ]);
  };

  const _getSelectedDevicesFromOtherPages = () => {
    const storedDeviceIds = rmcTableState.tableData.map((device) => device.deviceId);
    return rmcTableState.selectedTableData.filter((sd) => !storedDeviceIds.includes(sd.deviceId));
  };

  const handleTableRefresh = () => {
    rmcTableDispatcher.storePageSize(filterOptions.limit);
    rmcTableDispatcher.storeSearchItem('');
    rmcTableDispatcher.storeCfgApiResponse([]);
    rmcTableDispatcher.storeTotalItemsCount(0);
    rmcTableDispatcher.storeIsFetchingForTable(true);
    rmcTableDispatcher.storeCfgApiRequestOptions(filterOptions);
    rmcTableDispatcher.storeCurrentPage(1);
    rmcTableDispatcher.storeInitialColumns(rmcTableState.initialColumns ?? []);
    rmcTableDispatcher.storeTableData([]);
    rmcTableDispatcher.storeTableRefresh(true);
    rmcTableDispatcher.storeSelectedTableData([]);
    rmcTableDispatcher.storeShowModal(false);
  };

  const tableHandler = {
    handleSetTableData,
    handleSearch,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleSelect,
    handleSelectAllPageItems,
    handleFooterCancel,
    handleTableRefresh,
    handleFilterOption,
    handleFilterClearAll,
    getColumnContents,
  };

  return { tableHandler };
};

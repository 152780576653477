import React, { useMemo } from 'react';
import { CoreTableContainer } from './CoreTableContainer';
import { TableStoreProvider } from 'src/components/RmcComponent/RmcTable/tableStore';
import { TRmcTablePropsType } from 'src/components/RmcComponent/RmcTable/types';

export const RmcTable = (props: TRmcTablePropsType) => {
  const memoRenderComponent = useMemo(() => {
    return (
      <TableStoreProvider {...props}>
        <CoreTableContainer />
      </TableStoreProvider>
    );
  }, [
    props.config.id,
    props.config.apiConfig.cfgTotalItemsCount,
    props.config.columnConfig.cfgInitialColumnsList(),
    props.config.rendererConfig,
    props.config.modalConfig,
    props.config.showFilter,
    props.config.showCheckbox,
    props.config.showHeaderActionArea,
    props.config.showExportAll,
    props.config.showContextualFooterCondition,
  ]);

  return <>{memoRenderComponent}</>;
};

import styled from 'styled-components';
import token from '@veneer/tokens';
import React from 'react';
import { CheckBoxWithStarIcon } from './CheckBoxWithStarIcon';

export const FilterItems = (props: { filterLabel: string; filterItems: any }) => {
  return (
    <>
      <HorizontalLineText>
        <span>{props.filterLabel}</span>
      </HorizontalLineText>

      <FilterCheckBoxContent>
        {props.filterItems ? (
          props.filterItems.map((item, index) => (
            <CheckBoxWithStarIcon
              key={+index}
              filterLabel={props.filterLabel}
              checkBoxName={item}
            />
          ))
        ) : (
          <NoItems>No items</NoItems>
        )}
      </FilterCheckBoxContent>
    </>
  );
};

const FilterCheckBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const HorizontalLineText = styled.div`
  margin-top: 25px;

  span {
    display: flex;
    flex-direction: row;
    color: ${token.colorGray4};
    font-weight: bold;
    line-height: 20px;
  }
  span::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${token.colorGray3};
    margin: auto auto auto 5px;
  }
`;

const NoItems = styled.span`
  margin-left: 10px;
  color: ${token.colorGray4};
  font-weight: bold;
`;

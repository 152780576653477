import styled from 'styled-components';
import React from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { IconWarningAlt } from '@veneer/core';

export const EmptyTable = (props) => {
  const { tableConfig } = useTableStore();

  const TEXT = {
    zeroDevice: {
      status: t('table.empty.device.status'),
      detail: t('table.empty.device.detail'),
    },
    zeroJobs: {
      status: t('table.empty.jobs.status'),
      detail: '',
    },
    searchEmpty: {
      status: t('table.empty.search.status'),
      detail: t('table.empty.search.detail'),
    },
    error: {
      status: t('table.unable_to_load_data'),
      detail: '',
    },
  };

  const getEmptyTable = (type) => {
    switch (type) {
      case 'no_device':
        if (tableConfig.id == 'devices-table' || tableConfig.id == 'solutions-table') {
          return TEXT.zeroDevice;
        }
        if (tableConfig.id == 'jobs-table') {
          return TEXT.zeroJobs;
        }
        return TEXT.zeroDevice;
      case 'search_empty':
        return TEXT.searchEmpty;
      default:
        return TEXT.error;
    }
  };

  return (
    <NoItemTableWrapper>
      <IconBackground>
        <IconWarningAlt />
      </IconBackground>
      <DeviceStatusText>{getEmptyTable(props.type).status}</DeviceStatusText>
      <DeviceDetailText>{getEmptyTable(props.type).detail}</DeviceDetailText>
    </NoItemTableWrapper>
  );
};

const NoItemTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const IconBackground = styled.div`
  background: rgba(33, 33, 33, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeviceStatusText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  color: #404040;

  margin: 8px 0;
`;

const DeviceDetailText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: initial;
  text-align: justify;
  color: #404040;
`;

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export const exportCSVFile = async (fileTitle, items, headers, defaultColumns) => {
  const jsonObject = JSON.stringify(items);
  const csv = await _convertToCSV(jsonObject, headers, defaultColumns);
  const exportedFilename = `${fileTitle}.csv`;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const _convertToCSV = (objArray, headers, defaultColumns) => {
  const array = JSON.parse(objArray);
  const fields = Object.values(headers);

  const replacer = function (key, value) {
    return value;
  };
  let csv = array.map(function (row) {
    return fields
      .map(function (fieldName: string) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(',');
  });

  const headerColumn = headers.map((header) => {
    for (const column of defaultColumns) {
      if (header == column.id) {
        return column.label;
      }
    }
  });

  csv.unshift(headerColumn.join(',')); // add header column
  csv = csv.join('\r\n');

  return csv;
};

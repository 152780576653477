import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { Search } from '@veneer/core';
import { RmcCustomHook } from 'src/components/RmcComponent';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';

export const SearchList = (props) => {
  const { isMobile } = RmcCustomHook.useResponsive();
  const { rmcTableState } = useTableStore();
  const { tableHandler } = useTableHandler();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (rmcTableState.searchItem !== '') {
      setSearchValue(rmcTableState.searchItem);
    }
  }, [rmcTableState.searchItem]);

  const i18nSearch = {
    clear: t('table.clear'),
    showingResult: t('table.showing_result'),
    showingResults: t('table.showing_results'),
  };

  return (
    <div id="search-box-section">
      <StyledForm
        isMobile={isMobile}
        onSubmit={(e) => {
          // request search
          if (searchValue.length >= 3) {
            tableHandler.handleSearch(e, searchValue);
          }
          // refresh table in case of searchValue is empty.
          else if (searchValue === '') {
            tableHandler.handleSearch(e, '');
          }
          e.preventDefault();
        }}
        data-testid="search-form"
      >
        <StyledSearch
          disabled={props.disabled}
          id="controlled-search"
          data-testid="search-box"
          defaultValue={rmcTableState.searchItem}
          placeholder={t('table.search_placeholder')}
          maxLength={20}
          i18n={i18nSearch}
          onChange={(value) => {
            setSearchValue(value);
          }}
          onClear={() => tableHandler.handleSearch(null, '')}
        />
      </StyledForm>
    </div>
  );
};

const StyledForm = styled.form`
  .vn-input {
    min-height: 36px;

    ${(props) => {
      if (props.isMobile) {
        return css`
          width: 99.7%;
        `;
      } else {
        return css`
          width: 272px;
        `;
      }
    }}

`;

const StyledSearch = styled(Search)`
  input::placeholder,
  input::-webkit-input-placeholder {
    -webkit-text-fill-color: #adadad;
    font-size: 16px;
    line-height: 20px;
  }
`;

import { useState } from 'react';
import { telemetryAPI } from 'src/api/telemetry';
import { useStoreState } from 'src/store/useStoreState';

export const useApiConfig = () => {
  const { jobsState } = useStoreState();
  const { deviceId } = jobsState;
  const { getPrintJobs } = telemetryAPI();
  const [count, setCount] = useState(0);

  const cfgApiRequestOptions = {
    numDays: '10',
    startDate: '',
    endDate: '',
    pageNumber: '1',
    search: '',
  };

  const cfgApiResponse = async (requestOptions) => {
    const updatedRequestOptions = {
      ...cfgApiRequestOptions,
      pageNumber: requestOptions.offset ? requestOptions.offset + 1 + '' : '1',
      // maxPageSize: requestOptions.limit ? requestOptions.limit + '' : '25',
      search: requestOptions.search,
    };

    // call all data to get total count
    const { response: resForCount, error: errorForCount } = await getPrintJobs(
      cfgApiRequestOptions,
    );
    // call paged data
    const { response: resForPagedData, error: errorForPagedData } = await getPrintJobs(
      updatedRequestOptions,
    );

    if (errorForPagedData !== undefined || errorForCount !== undefined) {
      setCount(0);
      return { response: resForPagedData, error: errorForPagedData };
    }

    // Todo: need to check refined data
    const resForCountDataIdx = resForCount.printJobs.findIndex(
      (jobData) => jobData.deviceId == deviceId,
    );

    const resForPagedDataIdx = resForPagedData.printJobs.findIndex(
      (jobData) => jobData.deviceId == deviceId,
    );

    if (resForCountDataIdx == -1 || resForPagedDataIdx == -1) {
      //there is no job history about this deviceId in response
      setCount(0);
      return { response: [], error: errorForPagedData };
    }

    setCount(resForCount.printJobs[resForCountDataIdx].jobs.length);
    return {
      response: resForPagedData.printJobs[resForPagedDataIdx].jobs,
      error: errorForPagedData,
    };
  };

  return {
    apiConfig: {
      cfgTotalItemsCount: count,
      cfgApiRequestOptions,
      cfgApiResponse,
    },
  };
};

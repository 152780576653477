import React from 'react';
import HpProps from './hpProps';
import { Stack } from '@jarvis/web-stratus-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RmcTableBridge } from 'src/components/RmcComponent';

export const HpPropsProvider = (props) => {
  const flags = useFlags();

  return (
    <HpProps.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? Stack.pie,
        localization: props.localization,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        devicesTableBridge: RmcTableBridge('jobs-table'),
        featureFlags: flags,
      }}
    >
      {props.children}
    </HpProps.Provider>
  );
};

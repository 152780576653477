import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Table } from '@veneer/core';
import { EmptyTable } from './EmptyTable';
import { TableHeader } from './TableHeader';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';
import { TableContextualFooter } from './TableContextualFooter';
import {
  t,
  jsonParse,
  RetrieveI18nItems,
} from 'src/components/RmcComponent/RmcUtils/commonMethods';
import {
  preferences,
  defaultPageSizeOptions,
} from 'src/components/RmcComponent/RmcUtils/constants';

export const CoreTable = (props: { error: boolean }) => {
  const { tableConfig, rmcTableState, rmcTableDispatcher } = useTableStore();
  const { tableHandler } = useTableHandler();
  const [emptyState, setEmptyState] = useState('');
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(tableConfig.id), rmcTableState.initialColumns),
  );
  const numberOfSelectedItems = rmcTableState.selectedTableData.length;
  const i18nPagination = {
    currentPage: t('table.current_page'),
    goto: t('table.go_to_page'),
    next: t('table.next'),
    paginationNavigation: t('table.pagination_navigation'),
    pageSizeInformation: t('table.page_size_info'),
    previous: t('table.previous'),
    selectPageSize: t('table.select_page_size'),
  };

  useEffect(() => {
    if (props.error) {
      setEmptyState('error');
      return;
    }
    if (rmcTableState.totalItemsCount == 0 && rmcTableState.cfgApiRequestOptions.search) {
      setEmptyState('search_empty');
      return;
    }
    if (rmcTableState.totalItemsCount == 0) {
      setEmptyState('no_device');
    }
  }, [props.error, rmcTableState.totalItemsCount]);

  /**<CAUTION> 'defaultOrder' and 'columns' MUST BE SAME to enable disabled 'Reset to Default' button, else doesn't work
   - order : count of checked items in [Column Options] modal. It decides columns presented at initial Table
   - defaultOrder : columns array
   ex) ['A', 'B', 'C'] when clicked 'Reset to Default' button in [Column Options] modal
   - columns : columns presented in [Columns Options] Modal.
   ex) [{id: 'A'}, {id: 'B'}, {id: 'C'}]
   */
  const getPreferences = () => {
    return {
      ...preferences,
      defaultOrder: tableConfig.columnConfig.cfgInitialColumnsList(),
      order: order,
      width: tableConfig.columnConfig.cfgColumnsList()?.map((col) => {
        return {
          columnId: col.id,
          width: col.width,
        };
      }),
    };
  };

  const getActionArea = () => {
    if (tableConfig.showHeaderActionArea) {
      const disableCondition = props.error || rmcTableState.isFetchingForTable;

      return <TableHeader disable={disableCondition} />;
    } else {
      return <></>;
    }
  };

  const hasNoItem = () => {
    return rmcTableState.totalItemsCount == 0;
  };

  const getPagination = () => {
    return {
      currentPage: hasNoItem() ? 0 : rmcTableState.currentPage,
      i18n: i18nPagination,
      onPageChange: tableHandler.handlePageChange,
      onPageSizeChange: tableHandler.handlePageSizeChange,
      pageSize: hasNoItem() ? 1 : rmcTableState.pageSize,
      pageSizeOptions: defaultPageSizeOptions,
      popoverPlacement: 'top-start',
      totalItems: rmcTableState.totalItemsCount,
    };
  };

  const getSelectionState = () => {
    const total = rmcTableState.tableData.length;
    const selected = rmcTableState.tableData.filter((row) => row.rowConfig.selected).length;

    switch (selected) {
      case 0:
        return 'none';
      case total:
        return 'all';
      default:
        return 'indeterminated';
    }
  };

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder);
    sessionStorage.setItem(tableConfig.id, JSON.stringify(newOrder));
    rmcTableDispatcher.storeInitialColumns(newOrder);
  };

  const showContextualFooterCondition =
    numberOfSelectedItems > 0 && tableConfig.showContextualFooterCondition;

  return (
    <Wrapper data-testid={`${tableConfig.id}-section`}>
      <StyledTable
        data-testid={tableConfig.id}
        actionArea={getActionArea()}
        columnReorder={tableConfig.columnReorder}
        onColumnReorder={handleOnColumnReorder}
        component={'table'}
        customNoItems={<EmptyTable type={emptyState} />}
        columns={tableConfig.columnConfig.cfgColumnsList()}
        data={rmcTableState.tableData || []}
        onSort={tableHandler.handleSort}
        preferences={getPreferences()}
        pagination={getPagination()}
        onSelect={tableHandler.handleSelect}
        onSelectAllPageItems={tableHandler.handleSelectAllPageItems}
        rowSelectAllState={getSelectionState()}
        rowSelector={tableConfig.showCheckbox}
        i18n={RetrieveI18nItems()}
      />

      {showContextualFooterCondition && <TableContextualFooter />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;

  > div > div:nth-child(2)::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
  > div > div:nth-child(2)::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  > div > div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #888;
  }

  > div > div:nth-child(3) > div > div > button {
    height: 32px;
  }

  > div > div {
    height: auto;
  }
`;

const StyledTable = styled(Table)`
  pointer-events: ${(props) => (props.loading ? 'none' : '')};

  .css-xmh20k {
    display: none;
  }
`;

import React from 'react';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';

export const HighlightedText = (props: { value: string }) => {
  const { rmcTableState } = useTableStore();

  const getTextWithHighlights = (text, searchText) => {
    const regex = new RegExp(searchText, 'gi');
    const newText = text?.replace(regex, `<span style="color: #3ab4eb;">$&</span>`);
    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  };

  return <>{getTextWithHighlights(props.value, rmcTableState.searchItem)}</>;
};

import { useState } from 'react';
import { eventBus } from 'src/components/RmcComponent/RmcUtils/eventBus';
import { eventType, filterOptions } from 'src/components/RmcComponent/RmcUtils/constants';
import { TRmcTableBridgeEventsType } from './types';
import { TRmcTableStateType, TRmcTableStoreType } from 'src/components/RmcComponent/RmcTable/types';

export const RmcTableBridge = (tableId) => {
  const [store, setState] = useState<TRmcTableStoreType>({
    state: <TRmcTableStateType>{
      searchItem: '',
      cfgApiResponse: [],
      isFetchingForTable: true,
      cfgApiRequestOptions: filterOptions,
      currentPage: 1,
      modalConfig: [],
      initialColumns: [],
      tableData: [],
      tableFilter: '',
      tableRefresh: true,
      selectedTableData: [],
      pageSize: filterOptions.limit,
      showModal: false,
      columnContents: {},
      totalItemsCount: 0,
    },
  });

  const _emitEvent = (type, data = null) => {
    eventBus.$emit(`${tableId}`, {
      detail: { type: type, data: data },
    });
  };

  const refreshTable = () => _emitEvent(eventType.refreshTable);
  const openModal = () => _emitEvent(eventType.openModal);
  const closeModal = () => _emitEvent(eventType.closeModal);
  const openLoadingSection = () => _emitEvent(eventType.openLoadingSection);

  const events: TRmcTableBridgeEventsType = {
    refreshTable,
    openModal,
    closeModal,
    openLoadingSection,
  };

  return {
    state: store.state,
    setState,
    events,
  };
};

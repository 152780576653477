import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.jobs.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDeviceId = (_payload) => {
  return {
    type: store.jobs.DEVICE_ID,
    payload: _payload,
  };
};

import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu';
import { TablePreferences } from '@veneer/core/dist/scripts/table';

const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
};

export const currentStack = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  STAGE: window.location.origin === origin.STAGE,
  PROD: window.location.origin === origin.PROD,
};

export const constants = {
  currentStack,
};

/**
 * react-mfe-component internal usage
 */

export const filterOptions = {
  connectionState: '',
  outputLevel: 'minimal',
  sortBy: 'connectionState',
  order: 'descending',
  offset: 0,
  limit: 25,
  search: '',
  recursiveExpansion: true,
};

export const fullListOption = {
  limit: -1,
  offset: 0,
};

export const defaultPageSizeOptions: OptionInterface<number>[] = [
  { value: 5 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 },
];

export const preferences: TablePreferences = {
  sortBy: {
    id: 'connectionState',
    type: 'descending',
  },
};

export const eventType = {
  refreshTable: 'event/refreshTable',
  openModal: 'event/openModal',
  closeModal: 'event/closeModal',
  openLoadingSection: 'event/openLoadingSection',
};

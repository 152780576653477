import moment from 'moment';
import AssetsProviderFactory from 'src/components/RmcComponent/assets/AssetsProviderFactory';
import { RmcUtils } from 'src/components/RmcComponent';
import { JarvisAuthProvider } from '@jarvis/web-http';

type RmcPropsType = {
  language?: string;
  country?: string;
  stack?: number;
  authProvider?: JarvisAuthProvider;
};

const rmcProps: RmcPropsType = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

const setRmcProps = (props) => {
  rmcProps.language = props.localization.language;
  rmcProps.country = props.localization.country;
  rmcProps.stack = props.stack;
  rmcProps.authProvider = props.authProvider;
};

const getRmcProps = (): RmcPropsType => {
  return rmcProps;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getFormattedDate = (format, date) => {
  moment.locale(getRmcProps().language);
  return date ? moment(date).format(format) : '-';
};

export const commonMethods = {
  setRmcProps,
  getRmcProps,
  toTitleCase,
  getFormattedDate,
};

/**
 * react-mfe-component internal usage
 */

export const jsonParse = (text: string, defResult = []) => {
  return text ? JSON.parse(text) : defResult;
};

export const t = (subKey: string, args?: Record<string, string>): string => {
  const props = RmcUtils.commonMethods.getRmcProps();

  const assetsProvider = AssetsProviderFactory.create(props.language, props.country);
  return assetsProvider.getText(`ecp-mfe-component.${subKey}`, args);
};

export const RetrieveI18nItems = () => {
  return {
    cancelButton: t('table.cancel'),
    columnOptions: t('table.column_options'),
    resetToDefault: t('table.reset_to_default'),
    saveButton: t('table.save'),

    actionButton: t('table.select_an_action'),
    clearAllFilters: t('table.clear_filters'),
    columnResizeTooltip: t('table.resize_tool_tip'),
    deselectAllItems: t('table.deselect_all'),
    downButton: t('table.down_button'),
    itemSelected: t('table.item_selected'),
    itemsSelected: t('table.items_selected'),
    noItems: t('table.no_items'),

    selectAllItems: t('table.select_items'),
    selectAllPageItems: t('table.select_page_items'),
    sortedAscending: t('table.sorted_ascending'),
    sortedDescending: t('table.sorted_descending'),
    upButton: t('table.up_button'),
  };
};

export const objHasKeys = (obj, keys) => {
  const next = keys.shift();
  return obj[next] && (!keys.length || objHasKeys(obj[next], keys));
};

import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { getMockUpData, isMockUpMode } from 'src/utils/commonUtils';
import { useStoreState } from 'src/store/useStoreState';

export const telemetryAPI = () => {
  const { getApiClient } = useApiClient();
  const { jobsState } = useStoreState();
  const { deviceId } = jobsState;
  const client = getApiClient(service.telemetry);

  const getPrintJobs = async (options) => {
    let response, error;
    const numDays = options.numDays ?? '0'; // returns all data if '0'

    if (isMockUpMode()) {
      await getMockUpData('telemetry/get-print-jobs-1131').then((data) => {
        response = data;
      });
      return { response, error };
    }

    await client
      .getJobsByTenancy(deviceId, numDays, '', '', options.pageNumber, options.maxPageSize)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getPrintJobs,
  };
};

import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Checkbox } from '@veneer/core';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';

export const CheckBoxWithStarIcon = (props: { filterLabel: string; checkBoxName: string }) => {
  const { tableHandler } = useTableHandler();
  const { rmcTableState } = useTableStore();
  const [isFavorite, setIsFavorite] = useState('');

  const getIsChecked = () => {
    if (!rmcTableState.tableFilter) {
      return false;
    }
    let isChecked = false;
    JSON.parse(rmcTableState.tableFilter)[0].forEach((item) => {
      if (item.includes(props.checkBoxName)) {
        isChecked = true;
        return;
      }
    });
    return isChecked;
  };

  useEffect(() => {
    if (props.filterLabel === 'Favorite') {
      setIsFavorite('flex');
    } else {
      setIsFavorite('none');
    }
  }, [props.filterLabel]);

  return (
    <FilterItem display={isFavorite} data-testid="checkbox-with-start-icon">
      <Checkbox
        id="filter-checkbox"
        data-testid="filter-checkbox"
        className="item-margin-left"
        label={props.checkBoxName}
        name={`${props.filterLabel}:${props.checkBoxName}`}
        onChange={(e) => tableHandler.handleFilterOption(e)}
        checked={getIsChecked()}
      />
    </FilterItem>
  );
};

const FilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 40px;
  align-items: center;
  border-radius: 10px;
  &:hover {
    background-color: #f4f4f4;

    > div {
      display: flex;
    }
  }

  .item-margin-left {
    margin-left: 8px;
  }

  .item-margin-right {
    margin-right: 8px;
  }

  > div {
    display: ${(props) => props.display};
  }

  > div > #ellipsis-icon {
    margin-right: 5px;
  }

  .vn-checkbox__span--text {
    width: 150px;
  }
`;

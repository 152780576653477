import React from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import { Provider } from 'react-redux';
import { ShellProps } from './types/shell';
import { RootComponent } from '../src';
import { configureStore } from '@reduxjs/toolkit';
import { HpPropsProvider } from 'src/hpProps';
import { ToastProvider, ThemeProvider, useToast } from '@veneer/core';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps;
  const namespace = '@jarvis/react-ecp-device-details-job-history';
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();

  return (
    <section id={namespace}>
      <ThemeProvider {...themeProviderProps}>
        <ToastProvider>
          <HpPropsProvider
            ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
            id={packageInfo.name}
            stack={props.stack}
            shell={props.shell}
            localization={props.localization}
            useToast={useToast}
            authProvider={v1.authProvider}
            featureFlags={null}
          >
            <Provider store={store}>
              <RootComponent {...props} />
            </Provider>
          </HpPropsProvider>
        </ToastProvider>
      </ThemeProvider>
    </section>
  );
}

import styled, { css } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from '@veneer/core';

const Loader = ({ fullScreen }) => {
  return (
    <Wrapper fullScreen={fullScreen}>
      <ProgressIndicator />
    </Wrapper>
  );
};

Loader.defaultProps = {
  fullScreen: false,
};

Loader.propTypes = {
  fullScreen: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.fullScreen
      ? css`
          position: absolute;
          height: 100%;
          width: 100%;
        `
      : css`
          flex: 1;
          height: 100px;
        `}
`;

export default Loader;

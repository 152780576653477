import React from 'react';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';

const getSearchIdName = (type) => {
  const MAP = {
    'devices-table': 'deviceId',
    'solutions-table': 'deviceId',
    'jobs-table': 'jobId',
    'subscription-table': 'key',
  };
  return MAP[type] ?? '-';
};

export const useSearch = () => {
  const { rmcTableState, tableConfig } = useTableStore();
  const { tableHandler } = useTableHandler();
  const idName = getSearchIdName(tableConfig.id);
  const searchCols = tableConfig.columnConfig.cfgColumnsList().filter((col) => col.search);

  const triggerSearch = (totalDevices, searchKey) => {
    const key = searchKey?.toLowerCase() || '';

    if (!key || key == '') {
      return;
    }

    if (!totalDevices || (totalDevices && totalDevices.length == 0)) {
      return;
    }

    const { pagedItems, count } = getSearchResult(
      key,
      totalDevices,
      rmcTableState.cfgApiRequestOptions,
    );
    return { pagedItems, searchedItemsCount: count };
  };

  const getSearchResult = (searchKey, items, option) => {
    const contents = tableHandler.getColumnContents(items);
    const entries = Object.entries(contents).map(([id, entry]) => {
      const val = searchCols.reduce((r, col) => {
        r[col.id] = entry[col.id];
        return r;
      }, {});
      val['id'] = id;
      return val;
    });
    const result = entries.filter((item) => hasSearchKey(searchKey, item)).map((item) => item.id);
    const filteredItems = items.filter((item) => result.includes(item[idName]));
    const lastIndex = getSliceEnd(option, filteredItems.length);
    const pagedItems = filteredItems.slice(option.offset, lastIndex);

    return { pagedItems, count: filteredItems.length };
  };

  const hasSearchKey = (searchKey, item) => {
    for (const [columnName, value] of Object.entries(item)) {
      if (columnName === 'id') continue;
      if (value !== undefined && handleSearch(columnName, value, searchKey)) {
        return true;
      }
    }
    return false;
  };

  const handleSearch = (columnName, value, searchKey) => {
    if (value === null) return false;
    return value.toLowerCase().includes(searchKey);
  };

  const getSliceEnd = (option, length) => {
    const totalPageCount = Math.floor(length / option.limit) + 1;
    const currentPage = option.offset / option.limit;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * option.limit;
    } else {
      return length;
    }
  };

  return { triggerSearch };
};

import { RmcUtils } from 'src/components/RmcComponent';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const getLDProvider = async () => {
  let clientID;
  const CURRENT_STACK = RmcUtils.constants.currentStack;

  if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
  else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
  else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
  else {
    //production
    clientID = '6232996c7927321456ef8f82';
  }
  const Provider = await asyncWithLDProvider({
    clientSideID: clientID,
  });
  return Provider;
};

export const launchDarkly = {
  getLDProvider,
};

import { store } from 'src/utils/constants';

const initialState = {
  startRootComponent: false,
  deviceId: '',
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.jobs.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.jobs.DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    default:
      return state;
  }
};

export default jobsReducer;

import React from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { Button } from '@veneer/core';
import { exportCSVFile } from './exportMethods';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';

export const ExportActionButton = (props: { setShowModal: (boolean) => void }) => {
  const { useToast, rmcTableState, tableConfig } = useTableStore();

  const renderSuccess = () => {
    useToast.addToast({
      id: 'success',
      type: 'positive',
      text: t('table.export_success_msg'),
    });
  };

  const exportAction = async () => {
    const headers = tableConfig.columnConfig
      .cfgColumnsList()
      .map((col) => col.id)
      .filter((id) => id !== 'uid');

    const deviceData = Object.entries(rmcTableState.columnContents).map(([id, entry]) => {
      return entry;
    });

    await exportCSVFile(
      'deviceList',
      deviceData,
      headers,
      tableConfig.columnConfig.cfgColumnsList(),
    );

    renderSuccess();

    props.setShowModal(false);
  };

  return (
    <Button data-testid="export-button" onClick={() => exportAction()}>
      {t('table.export')}
    </Button>
  );
};

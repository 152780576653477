import styled from 'styled-components';
import React, { useState } from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { ExportAllButton } from './ExportAllButton';
import { ExportActionButton } from './ExportActionButton';
import { Button, Modal, Select } from '@veneer/core';

export const ExportList = (props: { disabled?: boolean }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState([1]);

  return (
    <Wrapper data-testid="export-list">
      <ExportAllButton disabled={props.disabled} setShowModal={setShowModal} />

      <StyledModal
        id="form-modal"
        data-testid="export-modal"
        className="body-large"
        closeOnBlur={false}
        show={showModal}
        title={t('table.export_devices')}
        footer={
          <ButtonGroup className="vn-button-group--responsive">
            <Button
              data-testid="cancel-button"
              appearance="secondary"
              onClick={() => setShowModal(false)}
            >
              {t('table.cancel')}
            </Button>

            <ExportActionButton setShowModal={setShowModal} />
          </ButtonGroup>
        }
      >
        <Description data-testid="description">{t('table.export_description')}</Description>

        <SelectorWrapper data-testid="selector-wrapper">
          <StyledSelect
            id="export-type"
            data-testid="export-type"
            label={t('table.select_file_type')}
            options={[{ value: 1, label: 'CSV' }]}
            clearIcon={false}
            value={selectedExportType}
            onChange={({ value: v }) => setSelectedExportType([v as number])}
          />
        </SelectorWrapper>
      </StyledModal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledModal = styled(Modal)`
  .vn-modal--content {
    max-width: 612px !important;
    padding: 32px 40px 32px 40px !important;
  }
`;

const StyledSelect = styled(Select)`
  line-height: 20px;
`;

const ButtonGroup = styled.div`
  text-align: right;
`;

const Description = styled.div`
  font-size: 18px;
  white-space: pre-wrap;
`;

const SelectorWrapper = styled.div`
  width: 200px;
  margin-top: 20px;
`;

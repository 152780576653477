import styled from 'styled-components';
import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types';

const LoaderContainer = ({ fullScreen }) => {
  return (
    <Wrapper>
      <Loader fullScreen={fullScreen} />
    </Wrapper>
  );
};

LoaderContainer.defaultProps = {
  fullScreen: false,
};

LoaderContainer.propTypes = {
  fullScreen: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export default LoaderContainer;

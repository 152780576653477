import styled, { css } from 'styled-components';
import React, { useEffect } from 'react';
import { Header } from '@veneer/core';
import { SearchList } from './SearchList';
import { ExportList } from './ExportList';
import { FilterTags } from './FilterTags';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { TableFilterContainer } from './TableFilterContainer';

export const TableHeader = (props: { loading?: boolean; disable?: boolean }) => {
  const { tableConfig, rmcTableState } = useTableStore();

  // remove 'select all N items' button
  useEffect(() => {
    document.querySelector('.css-yv343f')?.setAttribute('style', 'display: none;');
  }, [rmcTableState.selectedTableData]);

  return (
    <>
      <StyledHeader
        loading={props.loading}
        position="relative"
        data-testid="table-header"
        customStyle={{ border: 'none', background: 'transparent' }}
        leadingArea={
          <LeadingArea>
            <SearchList disabled={props.disable} />

            {tableConfig.showFilter && <TableFilterContainer isDisabled={props.disable} />}
          </LeadingArea>
        }
        trailingArea={
          tableConfig.showExportAll && <ExportList disabled={props.loading || props.disable} />
        }
      />

      {!props.loading &&
        rmcTableState.tableFilter != null &&
        Object.keys(rmcTableState.tableFilter).length > 0 && <FilterTags />}
    </>
  );
};

const StyledHeader = styled(Header)`
  ${(props) => {
    if (props.loading) {
      return css`
        margin-right: 30px;
      `;
    }
  }}
`;

const LeadingArea = styled.div`
  display: flex;
`;

import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import moment from 'moment';
import { dateFormat } from 'src/utils/constants';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = (props) => {
  localProps.authProvider = props.authProvider;
  localProps.language = props.localization.language;
  localProps.stack = props.stack;
  localProps.country = props.localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subKey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-device-details-job-history.${subKey}`, args);
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const refinedDate = (data: any) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(dateFormat.default);
    else return '--';
  }
};

import { createContext } from 'react';

const ecpDeviceV55 = null;
const stack = null;
const localization = null;
const useToast = null;
const authProvider = null;
const devicesTableBridge = null;
const featureFlags = null;

const hpProps = createContext({
  ecpDeviceV55,
  stack,
  localization,
  useToast,
  authProvider,
  devicesTableBridge,
  featureFlags,
});

export default hpProps;

import { createContext } from 'react';
import {
  TRmcTableStateType,
  TRmcTableConfigType,
  TRmcTableDispatcherType,
} from 'src/components/RmcComponent/RmcTable/types';

const useToast = null;
const tableConfig = <TRmcTableConfigType>null;
const rmcTableState = <TRmcTableStateType>null;
const rmcTableDispatcher = <TRmcTableDispatcherType>null;

const tableStore = createContext({
  useToast,
  tableConfig,
  rmcTableState,
  rmcTableDispatcher,
});

export default tableStore;

import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useState } from 'react';

export const usePagination = () => {
  const { rmcTableState } = useTableStore();
  const [memoizedJobs, setMemoizedJobs] = useState(null);

  const getPagination = (totalItems) => {
    if (memoizedJobs == null) {
      setMemoizedJobs(totalItems);
    }

    if (!totalItems) {
      return { pagedItems: [], count: 0 };
    }

    if (totalItems.length == 0) {
      return { pagedItems: [], count: 0 };
    }

    const { pagedItems, count } = _getSlicedData(
      totalItems,
      rmcTableState.currentPage,
      rmcTableState.pageSize,
    );
    return { pagedItems, count };
  };

  const _getSlicedData = (arr, currentPage, limit) => {
    const offset = (currentPage - 1) * limit;
    const lastIndex = _getSliceEnd(offset, limit, arr.length);
    const pagedItems = arr.slice(offset, lastIndex);
    return { pagedItems, count: arr.length };
  };

  const _getSliceEnd = (offset, limit, length) => {
    const totalPageCount = Math.floor(length / limit) + 1;
    const currentPage = offset / limit;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * limit;
    } else {
      return length;
    }
  };

  return [memoizedJobs, getPagination];
};

import { t } from 'src/utils/commonUtils';
import { RmcUtils } from 'src/components/RmcComponent';

export const useColumnConfig = () => {
  const initialColumns = ['jobName', 'date', 'jobCopies'];
  const prodExcludeIds = [''];

  const column = (id, label, width, required?) => {
    return {
      id,
      label,
      width: width,
      search: true,
      sortable: false,
      required: required ?? false,
    };
  };

  const tableColumns: Array<any> = [
    column('jobName', t('table.job_name'), 220, true),
    // column('documentType', t('table.document_type'), 220),
    // column('jobType', t('table.job_type'), 220),
    column('date', t('table.date'), 220),
    column('jobCopies', t('table.job_copies'), 220),
    // column('userName', t('table.user_name'), 220),
    { id: 'uid', label: 'ID', index: 'hidden', width: 10, sortable: false },
  ];

  const cfgInitialColumnsList = () => {
    return initialColumns;
  };

  const cfgColumnsList = () => {
    let filteredColumns = [...tableColumns];

    if (RmcUtils.constants.currentStack.PROD) {
      filteredColumns = filteredColumns.filter((col) => !prodExcludeIds.includes(col.id));
    }
    return filteredColumns;
  };

  return {
    columnConfig: {
      cfgInitialColumnsList,
      cfgColumnsList,
    },
  };
};

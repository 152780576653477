import styled, { keyframes } from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState } from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { Button, Select } from '@veneer/core';
import { LeftSelectedItem } from './LeftSelectedItem';

export const TableContextualFooter = () => {
  const { rmcTableState, rmcTableDispatcher, tableConfig } = useTableStore();
  const [selectValue, setSelectValue] = useState([]);

  const modalConfigForSelection =
    tableConfig.modalConfig?.cfgModalComponents()?.map((options) => ({
      label: options.label,
      value: options.value,
      disabled: options.disabled,
    })) ?? [];

  const modalConfigForShowModal =
    tableConfig.modalConfig?.cfgModalComponents()?.map((options) => ({
      component: options.component,
      value: options.value,
    })) ?? [];

  return (
    <>
      <Wrapper>
        <li>
          <LeftSelectedItem />

          <RightActionBox>
            <RightActionFlexBox>
              <SelectWrapper>
                <StyledSelect
                  options={modalConfigForSelection}
                  label={t('table.select_an_action')}
                  data-testid="contextual-footer-select"
                  id="contextual-footer-select"
                  clearIcon={false}
                  value={selectValue}
                  onChange={({ value: v }) => {
                    setSelectValue([v.toString()]);
                  }}
                />
              </SelectWrapper>

              <Button
                id="contextual-footer-continue-button"
                appearance="primary"
                onClick={() => rmcTableDispatcher.storeShowModal(true)}
                data-testid="contextual-footer-continue-button"
                disabled={selectValue.length == 0}
              >
                {t('table.continue')}
              </Button>
            </RightActionFlexBox>
          </RightActionBox>
        </li>
      </Wrapper>

      {rmcTableState.showModal && (
        <>
          {modalConfigForShowModal.map((option) => (
            <>{selectValue[0] === option.value && option.component}</>
          ))}
        </>
      )}
    </>
  );
};

const FooterAnimation = keyframes`
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: 993px) {
    width: calc(100% - 260px);
  }
  display: block;
  margin: 0;
  right: 0;
  z-index: 3;
  animation: 1s ease 0s 1 normal none running ${FooterAnimation};

  li {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0;
    border-style: solid;
    border-width: 0 1px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px ${tokens.space6};
    position: relative;
    width: 100%;
    background: #ebf9ff;
  }
`;

const RightActionBox = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  order: 2;
`;

const RightActionFlexBox = styled.div`
  display: flex;

  div {
    padding-right: 10px;

    div > div {
      padding-right: 16px;

      div + input + span {
        padding-right: 0;

        div.vn-select__open-button {
          padding-right: 0;
        }
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  > div {
    background-color: #ffffff;
  }
`;

const SelectWrapper = styled.div`
  min-width: 224px;
  max-width: 320px;
`;

import React from 'react';
import { Avatar, IconDocument } from '@veneer/core';
import { RmcTableEllipsisTooltip } from 'src/components/RmcComponent';
import { refinedDate } from 'src/utils/commonUtils';

export const useRendererConfig = () => {
  // Renderer Component should contain "value" props.
  const text = (id, value) => <RmcTableEllipsisTooltip id={id} value={value} />;

  const cfgTableRenderers = (jobsData) => {
    return jobsData.map((job) => ({
      ...job,
      objectUniqueKey: job.jobId,
      jobName: text('jobName', job.jobName),
      date: text('date', refinedDate(job.jobCompletionTime)),
      jobCopies: text('jobCopies', job.copiesCount + ''),
      rowConfig: {
        rowSelector: { icon: <Avatar icon={<IconDocument />} /> },
      },
    }));
  };

  return {
    rendererConfig: {
      cfgTableRenderers,
    },
  };
};

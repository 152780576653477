import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';
import { Button, ButtonGroup, Tag } from '@veneer/core';

export const FilterTags = () => {
  const [isExpand, setIsExpand] = useState(false);
  const { tableHandler } = useTableHandler();
  const { rmcTableState } = useTableStore();
  const { tableFilter } = rmcTableState;

  const filterOptions = new Map(JSON.parse(tableFilter));
  const buttons = [];
  let buttonCnt = 0;

  filterOptions.forEach((values: any, category) => {
    values.forEach((value) => {
      buttons.push(category + ':' + value);
    });
  });

  return (
    <FilterOptions>
      <ButtonGroup>
        {buttons.map((button) => {
          buttonCnt++;
          if (buttonCnt <= 3 || isExpand == true) {
            return (
              <Tag
                key={`filter-button-${button}`}
                data-testid="filter-tag"
                label={button}
                clearButton
                customStyle={FilterTagStyle}
                colorScheme="hpBlue"
                onClose={() => {
                  const target = { target: { name: button } };
                  tableHandler.handleFilterOption(target);
                }}
              />
            );
          } else return;
        })}
        {buttonCnt > 3 && isExpand == false && (
          <StyledButton
            small
            data-testid="tag-expand-btn"
            onClick={() => {
              setIsExpand(true);
            }}
          >{`+${buttonCnt - 3}`}</StyledButton>
        )}
        <Button
          small
          appearance="ghost"
          data-testid="tag-clear-all-btn"
          onClick={() => {
            tableHandler.handleFilterClearAll();
          }}
        >
          Clear All
        </Button>
      </ButtonGroup>
    </FilterOptions>
  );
};

const FilterOptions = styled.div`
  padding-top: 16px;

  .css-195awz {
    fill: #014667;
    max-width: 16px;
    max-height: 16px;
    width: 16px;
    height: 16px;
  }

  > div {
    align-items: center;
  }

  > div > button {
  }
`;

const FilterTagStyle = css`
  background: rgba(2, 122, 174, 0.1);
  color: #014667;

  height: 28px;

  > span {
    font-size: 16px;
  }

  > div {
    &:hover {
      background-color: rgba(2, 122, 174, 0.5);

      .css-195awz {
        fill: white;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  border-radius: 24px !important;
  height: 30px;
  color: #014667 !important;
  background-color: rgba(2, 122, 174, 0.1) !important;
  min-width: 0px !important;
  margin-left: 12px !important;

  &:hover {
    background-color: rgba(2, 122, 174, 0.5) !important;
  }
`;

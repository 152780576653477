import styled from 'styled-components';
import React from 'react';
import { TableHeader } from './CoreTable/TableHeader';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { IconGear, Table } from '@veneer/core';

export const LoadingTable = () => {
  const { tableConfig } = useTableStore();

  const getLoadingColumns = () => {
    const labels = tableConfig.columnConfig.cfgInitialColumnsList().map((col) => col.label);
    return labels.map((label, index) => ({
      id: index,
      label,
    }));
  };

  return (
    <StyledTable
      actionArea={
        <Wrapper data-testid={`${tableConfig.id}-loading-header`}>
          <TableHeader loading={true} disable={true} />
          <StyledIconGear />
        </Wrapper>
      }
      loading={true}
      loadingDataLength={5}
      data={[]}
      columns={getLoadingColumns()}
      rowSelector={tableConfig.showCheckbox ? 'multiSelection' : ''}
    />
  );
};

const StyledTable = styled(Table)`
  width: 100%;
  overflow-x: hidden;
  div {
    overflow-x: hidden;
  }
  div {
    margin-right: unset;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const StyledIconGear = styled(IconGear)`
  position: relative;
  fill: rgb(2, 120, 171) !important;
`;

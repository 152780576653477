import { useState, useRef, useEffect } from 'react';

const useConstructor = (
  callBack = () => {
    return;
  },
) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) {
    return;
  }

  callBack();
  setHasBeenCalled(true);
};

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};

const useWaitAnimation = (flag: boolean, waitTime = 500) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (flag) {
      setRender(true);
    } else {
      setTimeout(() => setRender(false), waitTime);
    }
  }, [flag]);

  return render;
};

const _useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(window.matchMedia(query).matches);
  }, []);

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    const handleChange = (e) => setMatches(e.matches);
    matchQueryList.addEventListener('change', handleChange);

    return () => {
      matchQueryList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};

const useResponsive = () => {
  const md = 1020;
  const sm = 768;
  const isPC = _useMediaQuery(`(min-width: ${md}px)`);
  const isTablet = _useMediaQuery(`(min-width: ${sm}px) and (max-width: ${md - 1}px)`);
  const isMobile = _useMediaQuery(`(max-width: ${sm - 1}px)`);

  return { isPC, isTablet, isMobile };
};

export const RmcCustomHook = {
  useConstructor,
  usePrevious,
  useDidMount,
  useWaitAnimation,
  useResponsive,
};

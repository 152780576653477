import React, { useMemo } from 'react';
import { RmcTable } from 'src/components/RmcComponent';
import { useHpProps } from 'src/hpProps';
import { useApiConfig } from './useApiConfig';
import { useColumnConfig } from './useColumnConfig';
import { useRendererConfig } from './useRendererConfig';

export const JobsTable = () => {
  const { apiConfig } = useApiConfig();
  const { columnConfig } = useColumnConfig();
  const { rendererConfig } = useRendererConfig();
  const { useToast, ecpDeviceV55, devicesTableBridge } = useHpProps();

  const syncBridgeNow = (newState) => devicesTableBridge.setState(newState);

  const memoRenderComponent = useMemo(() => {
    return (
      <RmcTable
        data-testid={'job-list-table'}
        config={{
          id: 'jobs-table',
          apiConfig,
          columnConfig,
          rendererConfig,
          showFilter: ecpDeviceV55,
          showCheckbox: 'avatar',
          showHeaderActionArea: true,
        }}
        onChange={syncBridgeNow}
        useToast={useToast}
      />
    );
  }, [apiConfig.cfgTotalItemsCount]);

  return <>{memoRenderComponent}</>;
};

import { useEffect } from 'react';

/**
 * Usage) eventBus.$on('eventType', (e) => console.log('All Event', e));
 * @param eventType
 * @param callbackFunc
 */
const $on = (eventType, callbackFunc) => {
  useEffect(() => {
    window.addEventListener(eventType, callbackFunc);
    return () => {
      window.removeEventListener(eventType, callbackFunc, true);
    };
  }, []);
};

/**
 * Usage) eventBus.$emit('eventType', { detail: newTableState });
 * @param eventType
 * @param callbackFunc
 */
const $emit = (eventType, callbackFunc) => {
  const event = new CustomEvent(eventType, callbackFunc);
  window.dispatchEvent(event);
};

const eventBus = { $on, $emit };

export { eventBus };

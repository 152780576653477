export const store = {
  jobs: {
    START_ROOT_COMPONENT: 'jobs/startRootComponent',
    DEVICE_ID: 'jobs/deviceId',
  },
};

export const service = {
  collection: 'collection',
  accountMgt: 'accountMgt',
  commercePortal: 'commercePortal',
  deviceCache: 'deviceCache',
  deviceShadow: 'deviceShadow',
  fleetMgt: 'fleetMgt',
  programMgt: 'programMgt',
  solutionRegistry: 'solutionRegistry',
  subscriptions: 'subscriptions',
  telemetry: 'telemetry',
};

export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

import React, { useEffect, useState, useMemo } from 'react';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { useSearch } from './useSearch';
import { CoreTable } from './CoreTable';
import { LoadingTable } from './LoadingTable';
import { RmcCustomHook } from 'src/components/RmcComponent';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { fullListOption } from 'src/components/RmcComponent/RmcUtils/constants';
import { useTableHandler } from './useTableHandler';
import { bridgeEventListener } from './bridgeEventListener';
import { usePagination } from './usePagination';

export const CoreTableContainer = () => {
  const { useToast, tableConfig, rmcTableState, rmcTableDispatcher } = useTableStore();
  const { tableHandler } = useTableHandler();
  const { triggerSearch } = useSearch();
  const [memoizedJobs, getPagination] = usePagination();

  const [error, setError] = useState(false);
  const didMount = RmcCustomHook.useDidMount();

  bridgeEventListener();

  useEffect(() => {
    if (!didMount) {
      return;
    }

    if (!rmcTableState.tableRefresh) {
      return;
    }

    /**
     *  This if phrase should be also removed when telemetry offset/limit is working fine. Before, it's calling total jobs so no need to call api every time
     *  If jobs are memoized, no need to call api. just use memoized data
     */
    if (memoizedJobs != null) {
      setTableColumns();
      setTablePageData(getPagination(memoizedJobs).pagedItems, getPagination(memoizedJobs).count);
      return;
    }

    rmcTableDispatcher.storeIsFetchingForTable(true);

    apiGetResponse()
      .then(({ devices, count, isError }) => {
        rmcTableDispatcher.storeCfgApiResponse(devices);
        setTableColumns();
        setTablePageData(devices, count);
        setError(isError);
      })
      .finally(() => {
        rmcTableDispatcher.storeIsFetchingForTable(false);
      });
  }, [didMount, rmcTableState.cfgApiRequestOptions, rmcTableState.tableRefresh]);

  useEffect(() => {
    if (error) {
      renderError();
    }
  }, [error]);

  const apiGetResponse = async () => {
    console.log(`[${tableConfig.id}] apiGetResponse`);

    let reqParam = rmcTableState.cfgApiRequestOptions;

    if (rmcTableState.cfgApiRequestOptions.search) {
      reqParam = { ...rmcTableState.cfgApiRequestOptions, ...fullListOption };
    }

    const { response, error } = await tableConfig.apiConfig.cfgApiResponse(reqParam);

    if (error != undefined) {
      return { devices: [], count: 0, isError: true };
    }

    /**
     *  Remove wrapping response with getPagination() when telemetry offset/limit is working fine
     *  getPagination() is a manually implemented pagination method.
     */
    return {
      devices: getPagination(response).pagedItems,
      count: getPagination(response).count,
      isError: false,
    };
  };

  const setTablePageData = (data, count) => {
    let items = data;
    let itemsSize = count;

    if (rmcTableState.cfgApiRequestOptions.search) {
      const validResult = triggerSearch(data, rmcTableState.cfgApiRequestOptions.search);
      if (validResult) {
        items = validResult.pagedItems;
        itemsSize = validResult.searchedItemsCount;
      }
    }
    tableHandler.handleSetTableData(items);
    rmcTableDispatcher.storeTotalItemsCount(itemsSize);
  };

  const setTableColumns = () => {
    const sessionColumn = sessionStorage.getItem(tableConfig.id);
    const parsedColumn = JSON.parse(sessionColumn);

    if (sessionColumn !== null && parsedColumn.length > 0) {
      initColumnOrder(parsedColumn);
    } else {
      initColumnOrder(rmcTableState.initialColumns);
    }
  };

  const initColumnOrder = (newOrder) => {
    sessionStorage.setItem(tableConfig.id, JSON.stringify(newOrder));
  };

  const renderError = () => {
    useToast.addToast({
      id: 'retry',
      type: 'negative',
      text: t('table.unable_to_load_data'),
    });
  };

  const enableCondition = !rmcTableState.isFetchingForTable;

  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      console.log(`[${tableConfig.id}] render <CoreTable />`);

      return <CoreTable {...{ error }} data-testid="core-table-container" />;
    } else {
      return <></>;
    }
  }, [
    enableCondition,
    rmcTableState.tableData,
    rmcTableState.totalItemsCount,
    rmcTableState.currentPage,
    rmcTableState.selectedTableData,
    rmcTableState.pageSize,
  ]);

  return <>{rmcTableState.isFetchingForTable ? <LoadingTable /> : memoRenderComponent}</>;
};

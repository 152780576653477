import { eventBus } from 'src/components/RmcComponent/RmcUtils/eventBus';
import { eventType } from 'src/components/RmcComponent/RmcUtils/constants';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';

export const bridgeEventListener = () => {
  const { tableHandler } = useTableHandler();
  const { tableConfig, rmcTableDispatcher } = useTableStore();

  eventBus.$on(`${tableConfig.id}`, (e: any) => {
    const payload: { type; data } = e.detail;

    switch (payload.type) {
      case eventType.refreshTable:
        tableHandler.handleTableRefresh();
        rmcTableDispatcher.storeTableRefresh(false);
        setTimeout(() => rmcTableDispatcher.storeTableRefresh(true), 100);
        break;

      case eventType.openModal:
        rmcTableDispatcher.storeShowModal(true);
        break;

      case eventType.closeModal:
        rmcTableDispatcher.storeShowModal(false);
        break;

      case eventType.openLoadingSection:
        rmcTableDispatcher.storeIsFetchingForTable(true);
        break;
    }
  });

  return { bridgeEventListener };
};

import 'src/styles/global.scss';
import styled from 'styled-components';
import React from 'react';
import { setProps } from 'src/utils/commonUtils';
import { JobsTable } from 'src/components/RootComponent/JobsTable';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { getJobsStoreActions } from 'src/store/jobs/init';
import { RmcCustomHook, RmcUtils } from 'src/components/RmcComponent';

export const RootComponent = (props) => {
  const { jobsState } = useStoreState();
  const { startRootComponent } = jobsState;
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  RmcCustomHook.useConstructor(() => {
    setProps(props);
    RmcUtils.commonMethods.setRmcProps(props);

    dispatchAll(getJobsStoreActions(props));
  });

  const didReduxUpdated = () => {
    return startRootComponent;
  };

  const renderRootComponent = () => {
    if (didReduxUpdated()) {
      return (
        <Wrapper data-testid={'job-list-table-wrapper'}>
          <JobsTable />
        </Wrapper>
      );
    }
  };

  return renderRootComponent();
};

const Wrapper = styled.div``;

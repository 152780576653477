import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { t } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { Button } from '@veneer/core';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { useTableHandler } from 'src/components/RmcComponent/RmcTable/CoreTableContainer/useTableHandler';

export const LeftSelectedItem = () => {
  const { tableHandler } = useTableHandler();
  const { rmcTableState } = useTableStore();
  const selectedDataLength = rmcTableState.selectedTableData.length;

  return (
    <>
      <CancelButton
        id="contextual-footer-cancel-button"
        appearance="secondary"
        onClick={() => tableHandler.handleFooterCancel()}
        data-testid="contextual-footer-cancel-button"
      >
        {t('table.cancel')}
      </CancelButton>

      <ItemsSelectedBox>
        <label data-testid="contextual-footer-selected-label">
          {selectedDataLength}{' '}
          {selectedDataLength > 1 ? t('table.items_selected') : t('table.item_selected')}
        </label>
      </ItemsSelectedBox>
    </>
  );
};

const CancelButton = styled(Button)`
  margin-right: ${tokens.space4} !important;
`;

const ItemsSelectedBox = styled.div`
  display: inline-flex;
  flex: 1 1 100%;
  order: 1;
  font-family: ${tokens.fontFamilyRegular};
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
  vertical-align: middle;
`;

import styled from 'styled-components';
import token from '@veneer/tokens';
import React, { useState } from 'react';
import { FilterItems } from './FilterItems';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { Header, IconFunnel, Search, SideBar } from '@veneer/core';

export const TableFilter = (props: { disabled?: boolean }) => {
  const { rmcTableState } = useTableStore();
  const [showSideBar, setShowSideBar] = useState(false);

  const handleShowSideBar = () => {
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);
  };

  const getFilterCount = () => {
    const parsedFilter = JSON.parse(rmcTableState.tableFilter);
    if (parsedFilter === null) {
      return 0;
    }
    let flatFilterItems = [];
    parsedFilter
      .map((obj) => obj[1])
      .forEach((obj) => {
        flatFilterItems = [...obj];
      });
    return flatFilterItems.length;
  };

  const doHaveFilter = getFilterCount() > 0;

  const builtInFilterList = ['Online', 'Offline', 'Unknown'];

  return (
    <>
      <FilterWrapper
        data-testid="table-filter-entry"
        onClick={() => handleShowSideBar()}
        disabled={props.disabled}
      >
        <IconFunnel filled={doHaveFilter} color={props.disabled ? 'colorGray3' : 'colorHpBlue6'} />
        Filter {doHaveFilter ? getFilterCount() : ''}
      </FilterWrapper>

      <SideBar
        id="filter-sidebar"
        behavior="overlay"
        position="end"
        show={showSideBar}
        onClose={handleShowSideBar}
        content={
          <FilterContent>
            <p id="filter-title">Filter</p>
            <p id="filter-subtitle">Select filters to view in the list.</p>
            {/* Search */}
            <Header
              id="filter-search"
              position="relative"
              leadingArea={<Search id="controlled-search" placeholder="Search filters" />}
            />

            {/* Favorite */}
            <FilterItems filterLabel="Favorite" filterItems={null} />

            {/* Built-in */}
            <FilterItems filterLabel="Connectivity" filterItems={builtInFilterList} />

            {/* Custom */}
            <FilterItems filterLabel="Custom" filterItems={null} />
          </FilterContent>
        }
      />
    </>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => (props.disabled ? '#adadad' : token.colorHpBlue6)};
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  padding: 0 20px;
  border-radius: 12px;
  &:hover {
    // colorHpBlue0
    background-color: rgba(235, 249, 255, 0.5);
  }
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 5px 20px;

  #filter-title {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  #filter-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .margin-top20 {
    margin-top: 20px;
  }

  #create-filter-delete-button {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
`;

import { useRef } from 'react';
import { service } from 'src/utils/constants';
import { getProps } from 'src/utils/commonUtils';
import { TelemetryClient } from '@jarvis/web-stratus-client';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getApiClient = (serviceName) => {
    let client;

    switch (serviceName) {
      case service.telemetry:
        client = useRef<TelemetryClient>(null);
        client.current = new TelemetryClient(stack, authProvider);
        break;
    }

    return client.current;
  };

  return {
    getApiClient,
  };
};

import styled from 'styled-components';
import React from 'react';
import { TableFilter } from './TableFilter';

export const TableFilterContainer = (props: { isDisabled?: boolean }) => {
  return (
    <Wrapper>
      <TableFilter
        {...{
          disabled: props.isDisabled,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding-left: 12px;
`;

import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@veneer/core';
import { RmcCustomHook } from 'src/components/RmcComponent';
import { useTableStore } from 'src/components/RmcComponent/RmcTable/tableStore';
import { HighlightedText } from './HighlightedText';

export const RmcTableEllipsisTooltip = (props: { id: string; value: string }) => {
  const { rmcTableState } = useTableStore();
  const [renderTooltip, setRenderTooltip] = useState(false);
  const didMount = RmcCustomHook.useDidMount();
  const columnRef = useRef<HTMLElement>();
  const initialColumns = rmcTableState.initialColumns;

  useEffect(() => {
    window.addEventListener('resize', trackEllipsis);
    return () => {
      window.removeEventListener('resize', trackEllipsis);
    };
  }, []);

  useEffect(() => {
    trackEllipsis();
  }, [initialColumns, didMount]);

  const trackEllipsis = () => {
    setTimeout(() => {
      if (isEllipsisActive(columnRef.current)) {
        setRenderTooltip(true);
      } else {
        setRenderTooltip(false);
      }
    }, 300);
  };

  const isEllipsisActive = (element) => {
    if (element == null) return false;

    const columnContainer = document.querySelector(`#${props.id}`);
    const text = element.querySelector('span');
    const margin = 25;

    return text.offsetWidth > columnContainer.scrollWidth - margin;
  };

  const renderContent = () => {
    return (
      <Content ref={columnRef}>
        <HighlightedText value={props.value} />
      </Content>
    );
  };

  return (
    <Wrapper>
      {renderTooltip ? (
        <Tooltip
          arrow
          content={<p className="tooltipContent">{props.value}</p>}
          placement="bottom-start"
          portal
          useJsPositioning
        >
          {renderContent()}
        </Tooltip>
      ) : (
        <>{renderContent()}</>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 320px;
  font-size: 16px;

  .tooltipContent {
    font-family: 'Forma DJR Micro';
    font-size: 12px;
    line-height: 16px;
  }

  #jsTooltipPosition {
    white-space: normal;
    word-break: break-word;
  }

  #noTooltip {
    display: none;
  }
`;

import React, { useEffect, useState } from 'react';
import TableStore from './tableStore';
import { objHasKeys } from 'src/components/RmcComponent/RmcUtils/commonMethods';
import { filterOptions } from 'src/components/RmcComponent/RmcUtils/constants';
import {
  TRmcTableStoreType,
  TRmcTableStateType,
  TRmcTableConfigType,
  TRmcTableDispatcherType,
} from 'src/components/RmcComponent/RmcTable/types';

export const TableStoreProvider = (props) => {
  // rmcTableState
  const [searchItem, storeSearchItem] = useState('');
  const [cfgApiResponse, storeCfgApiResponse] = useState([]);
  const [isFetchingForTable, storeIsFetchingForTable] = useState(true);
  const [cfgApiRequestOptions, storeCfgApiRequestOptions] = useState(
    props.config.apiConfig.cfgApiRequestOptions,
  );
  const [currentPage, storeCurrentPage] = useState(1);
  const [initialColumns, storeInitialColumns] = useState(
    props.config.columnConfig.cfgInitialColumnsList() ?? [],
  );
  const [tableData, storeTableData] = useState([]);
  const [tableRefresh, storeTableRefresh] = useState(true);
  const [selectedTableData, storeSelectedTableData] = useState([]);
  const [tableFilter, storeTableFilter] = useState(null);
  const [showModal, storeShowModal] = useState(false);
  const [columnContents, storeColumnContents] = useState({});
  const [totalItemsCount, storeTotalItemsCount] = useState(
    props.config.apiConfig.cfgTotalItemsCount ?? 0,
  );
  const [pageSize, storePageSize] = useState(
    objHasKeys(props.config.apiConfig.cfgApiRequestOptions, ['limit'])
      ? props.config.apiConfig.cfgApiRequestOptions.limit
      : filterOptions.limit,
  );

  useEffect(() => {
    onChangeCallback();
  });

  const onChangeCallback = () => {
    const rmcTableStore: TRmcTableStoreType = {
      state: rmcTableState,
    };
    // set table state
    props.onChange(rmcTableStore);
  };

  const tableConfig: TRmcTableConfigType = {
    // required config
    id: props.config.id ?? 'table',
    apiConfig: props.config.apiConfig,
    columnConfig: props.config.columnConfig,
    rendererConfig: props.config.rendererConfig,
    // optional config
    modalConfig: props.config.modalConfig,
    showFilter: props.config.showFilter ?? false,
    showCheckbox: props.config.showCheckbox ?? false,
    showHeaderActionArea: props.config.showHeaderActionArea ?? false,
    showExportAll: props.config.showExportAll ?? false,
    showContextualFooterCondition: props.config.showContextualFooterCondition ?? false,
    // ui config
    columnReorder: props.config.columnReorder ?? true,
  };

  const rmcTableState: TRmcTableStateType = {
    searchItem,
    isFetchingForTable,
    currentPage,
    initialColumns,
    tableData,
    tableRefresh,
    selectedTableData,
    tableFilter,
    showModal,
    columnContents,
    totalItemsCount,
    cfgApiResponse,
    cfgApiRequestOptions,
    pageSize,
  };

  const rmcTableDispatcher: TRmcTableDispatcherType = {
    storeSearchItem,
    storeIsFetchingForTable,
    storeCurrentPage,
    storeInitialColumns,
    storeTableData,
    storeTableFilter,
    storeTableRefresh,
    storeSelectedTableData,
    storeShowModal,
    storeColumnContents,
    storeTotalItemsCount,
    storeCfgApiResponse,
    storeCfgApiRequestOptions,
    storePageSize,
  };

  return (
    <TableStore.Provider
      value={{
        useToast: props.useToast,
        tableConfig,
        rmcTableState,
        rmcTableDispatcher,
      }}
    >
      {props.children}
    </TableStore.Provider>
  );
};
